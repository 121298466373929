
























































































import { Component, Vue } from 'vue-property-decorator';
import BillingRepository from '@/api/repositories/BillingRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';
import Billing from '@/models/Billing';
import moment from 'moment';

const billingRepository: BillingRepository = RepositoryFactory.get('billing');

@Component({
    components: {
        BillingTableComponent: () => import(
            /* webpackChunkName: "BillingTableComponent" */
            '@/components/BillingTable.component.vue')
    }
})
export default class RidesView extends Vue {
    public startMenu: boolean = false;
    public endMenu: boolean = false;
    public startDate: string | null = null;
    public endDate: string | null = null;

    private search: string = '';

    created() {
        const today = moment(new Date());
        this.endDate = today.format('yyyy-MM-DD');
        today.set({ date: 1 });
        this.startDate = today.format('yyyy-MM-DD');
    }

    public billingData: Billing[] | null = null;

    private get billings(): Billing[] {
        return this.billingData ?? [];
    }

    public async generateClicked() {
        if (!this.startDate || !this.endDate) {
            return;
        }

        const response = await billingRepository.get({ from: this.startDate, to: this.endDate });
        this.billingData = Billing.parseFromArray(response.data) as Billing[];
    }
}
