import Ride from '@/models/Ride';
import { PAYMENT_TYPE } from '@/models/Payment';

export default class BillingDetails {
    public earnings!: number;
    public cashEarnings!: number;
    public paytodayEarnings!: number;
    public paytodayFee!: number;
    public share: number = 0;

    constructor(rides: Ride[]) {
        this.earnings = rides.reduce((sum, ride) => sum + ride.offer.price, 0);
        this.cashEarnings = rides.filter(ride => ride.payment?.type! === PAYMENT_TYPE.CASH)
            .reduce((sum, ride) => sum + ride.offer.price, 0);
        this.paytodayEarnings = rides.filter(ride => ride.payment?.type! === PAYMENT_TYPE.PAYTODAY)
            .reduce((sum, ride) => sum + ride.offer.price, 0);

        this.paytodayFee = Number((this.paytodayEarnings *
            (process.env.VUE_APP_P2D_HAS_ACCOUNT === 'true' ? 0.023 : 0.035)).toFixed(2));
        this.paytodayEarnings = this.paytodayEarnings - this.paytodayFee;
        this.share = -this.paytodayEarnings.toFixed(2);
    }
}
